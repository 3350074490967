.search-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .search-item {
    padding: 4px;
    border: 1px solid rgba(255 255 255 / 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    cursor: pointer;
    transition: 0.1s;
  }
  img {
    width: 120px;
    height: 160px;
    border: 1px solid rgba(61, 71, 81, 0.3);
    border-radius: 8px;

    @media (max-width: 768px) {
      width: 80px;
      height: 100px;
    }
  }
}
