.swiper {
  height: 380px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(61, 71, 81, 0.3);

  @media (max-width: 768px) {
    height: 320px;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
